/*
 * @Descripttion:专家服务
 * @Author: 张城勇
 * @Date: 2021-10-25 10:22:05
 * @LastEditors: zcy
 * @LastEditTime: 2022-01-26 13:35:35
 */
import axios from '@/axios'


// 获取验证码
export function getTestNum(phone) {
  return axios({
    url: `/haojiang-portal/appletUser/sendVerificationCode/${phone}`,
    method: 'get'
  })
}

// 登录请求
export function loginUser(data){
  return axios({
    url: `/haojiang-admin/systemUserInfo/loginByHomePage`,
    method: 'post',
    data
  })
}

// 注册请求
export function registerUser(data) {
  return axios({
    url: `/haojiang-portal/frontQuestion/insertUserImformation`,
    method: 'post',
    data
  })
}

// 上传图片
export function uploadImage(data){
  return axios({
    url: `/haojiang-admin/material/uploadImage`,
    method: 'post',
    data
  })
}

// 提交问题
export function saveProblem(data){
  return axios({
    url: `/haojiang-portal/frontQuestion/submitMessageCommit`,
    method: 'post',
    data
  })
}

// 获取专家服务列表
export function getPageList(data){
  return axios({
    url: `/haojiang-portal/frontQuestion/getServiceMessage`,
    method: 'post',
    data
  })
}

// id获取详情数据
export function getIdInfo(titleId){
  return axios({
    url: `/haojiang-portal/frontQuestion/getMessageDetails/${titleId}`,
    method: 'get',
  })
}

// 提问详情回复
export function editProblem(data){
  return axios({
    url: `/haojiang-portal/frontQuestion/submitMessageComment`,
    method: 'post',
    data
  })
}

// 获取我的提问page
export function myProblemPage(data){
  return axios({
    url: `/haojiang-portal/frontQuestion/getMessageRecord`,
    method: 'post',
    data
  })
}

// 根据id删除提问
export function deleteProblem(id){
  return axios({
    url: `/haojiang-portal/frontQuestion/DeleteMessage/${id}`,
    method: 'delete',
  })
}

// 修改密码
export function updatePassword(data){
  return axios({
    url: `/haojiang-admin/systemUserInfo/updatePassword`,
    method: 'post',
    data
  })
}

// 根据id修改用户信息
export function updatePhone(data){
  return axios({
    url: `/haojiang-portal/frontQuestion/updatetUserphone`,
    method: 'post',
    data
  })
}

// 手机验证码登录
export function PhoneCodeLogin(data){
  return axios({
    url: `/haojiang-admin/systemUserInfo/loginByPhone`,
    method: 'post',
    data
  })
}
