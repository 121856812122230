<template>
  <div class="gateway zy-brand">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="zy-brand-banner">
      <img :src="bannerUrl" alt />
    </div>
    <!-- 濠江名优产品 -->
    <div class="famous-products gateway-module" @mouseleave="remove">
      <div class="module-title">濠江名优产品</div>
      <div class="module-content">
        <div v-show="ChuangpingData.length" class="content-box" :key="index" v-for="(i, index) in ChuangpingData">
          <!-- 图片 -->
          <div @mouseover="clickItem(i2.id, $event)" :class="[
            'content-box-small',
            i2.id == lsHoverIdx ? 'big-content-box' : '',
          ]" v-for="i2 in i" :key="i2.id">
            <img :style="
              i2.id == lsHoverIdx ? 'width:100%;transition: all .3s .1s;' : ''
            " :src="i2.url" />
            <div :class="[i2.id == lsHoverIdx ? 'img-mask' : '']">
              <div :class="[
                'box-text',
                i2.id == lsHoverIdx ? 'box-mask-text' : '',
              ]">
                {{ i2.name }}
              </div>
              <div :style="i2.id == lsHoverIdx ? 'width:27px;' : 'width:0; '" class="box-icon"></div>
              <div :style="i2.id == lsHoverIdx ? '' : 'visibility: hidden;; '" class="box-content">
                {{ i2.introduce }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 濠江品牌资质 -->
    <div class="brand-qualification gateway-module">
      <div class="module-title">濠江品牌资质</div>
      <div class="module-content">
        <swiper class="swiper" ref="swiper" :options="swiperOption" v-if="this.zhiziData.length>1">
          <swiper-slide v-for="ele in zhiziData" :key="ele.id">
            <div class="item-wrap">
              <div class="img-box">
                <img :src="ele.url" alt />
              </div>
              <span>{{ ele.name }}</span>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <div class="no-swiper" v-else>
          <div class="item-wrap" v-for="ele in zhiziData" :key="ele.id">
            <div class="img-box">
              <img :src="ele.url" alt />
            </div>
            <span>{{ ele.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <gateway-footer />
    <ToTopperComponent />
  </div>
</template>
<script>
import { ZhanyuChuangpin, ZhanyuZizi, initBannerImg } from './modules/zy-brand'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
export default {
  name: 'zy-brand',
  data() {
    return {

      // 背景图片Url
      bannerUrl: '',
      // 名优创品数组
      ChuangpingData: [],
      // 品牌资质数组
      zhiziData: [],
      lsHoverIdx: 1,
      lsHovertime: false,
      // 小于三张的情况
      swiperOption: {
        // loop:true,
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000, //1秒切换一次
          loop: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 回调函数
        on: {
          slideChangeEnd: function () {
            alert('index' + this.activeIndex)
          },
        },
      },
    }
  },
  computed: {},
  methods: {
    // 鼠标移入事件
    clickItem(idx, e) {
      this.lsHoverIdx = idx
    },
    // 鼠标失焦事件
    remove() {
      this.lsHoverIdx = 0
    },
    async getZhiziData() {
      let res = await ZhanyuZizi()
      let { data } = res
      this.zhiziData = data
    },
    // 初始化背景图片
    async getBannerimg() {
      let res = await initBannerImg()
      let { data } = res
      this.bannerUrl = data[0].url
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    swiper,
    swiperSlide,
    ToTopperComponent,
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  async mounted() {
    //  let fun= this.$refs.swiper.swiper.onTouchEnd
    //--------------------------初始化背景图片
    this.getBannerimg()

    //--------------------------湛鱼名优产品请求以及处理部分
    let res = await ZhanyuChuangpin()
    if (res.code !== '00000') {
      return this.$message.error('获取濠江名创优品失败')
    }
    const { data } = res
    //---------------------------将后端数据封装成二维数组
    let num = data.length //总图片长度
    let arr = [] //存放图片二维数组的一维数组
    let arr2 = [] //每四张图片一组的二维数组
    for (let i = 0; i < num; i++) {
      let i2 = i + 1
      arr2.push(data[i])
      if (i2 % 4 == 0) {
        arr.push(arr2)
        arr2 = []
      }
    }
    arr.push(arr2)
    arr.forEach((item) => {
      if (item.length == 0) {
        let num = arr.indexOf(item)
        arr.splice(num, 1)
      }
    })
    //-----------------------------------------------end
    this.ChuangpingData = arr

    setTimeout(() => {
      let all = document.querySelectorAll('.content-box')
      for (let i = 0; i < all.length; i++) {
        let num = all[i].childNodes.length
        //找到图片不足四张的二维数组
        if (num == 1 || num == 2 || num == 3) {
          let el = all[i].childNodes
          for (let i = 0; i < el.length; i++) {
            let el2 = el[i].childNodes
            el2[1].childNodes[2].style.width = '385px'
          }
        }
      }
    })
    //--------------------------湛鱼名优产品请求以及处理部分end

    //--------------------------湛鱼品牌资质请求以及处理部分
    this.getZhiziData()

    // var mySwiper = new swiper('.swiper', {
    //   // onInit: function (swiper) {
    //   //   console.log('初始化');
    //   //   //Swiper初始化了
    //   //   //alert(swiper.activeIndex);提示Swiper的当前索引
    //   // },
    // })

  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './zy-brand.less';

</style>
