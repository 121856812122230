<!--
 * @Descripttion:专家服务
 * @Author: zcy
 * @Date: 2021-11-24 15:39:49
 * @LastEditors: zcy
 * @LastEditTime: 2022-09-16 10:07:06
-->
<template>
  <div class="gateway expertServices">
    <!-- nav头部导航栏 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />
    <!-- 头部banner图 -->
    <div class="park-service-banner">
      <div class="img-box">
        <img :src="bannerUrl" alt="" />
      </div>
    </div>
    <!-- content_nav导航栏 -->
    <div class="main-content gateway-module">
      <div class="module-content">
        <div class="sub-nav-box">
          <li :class="['ls-item', { active: idx === lsHoverIdx - 1 }]" v-for="(ele, idx) in navLs" :key="idx"
            @click="clickItem(idx)">
            {{ ele.name }}
          </li>
          <div class="sub-nav-login" v-if="true">
            <span v-if="loginName">
              <el-dropdown @command="loginOut">
                <span class="el-dropdown-link">
                  {{ loginName
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item style="white-space: nowrap" command="loginOut">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <div v-else>
              <span @click="loginClick">登录</span>
              ｜<span @click="registerClick">注册</span>
            </div>
          </div>
        </div>
        <!-- content部分搜索框 -->
        <div class="search-box" v-if="lsHoverIdx == 1">
          <el-input placeholder="请输入标题" v-model="params.param.title" @change.native="getListData">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div v-if="lsHoverIdx == 1" class="sub-content-page">
          <div class="page-box" :key="index" v-for="(item, index) in dataList" @click="pageBoxClick(item.id)">
            <div class="page-box-title">{{ item.title }}</div>
            <div class="page-box-user">
              <div class="user-text">{{ item.nickName }}</div>
              <div class="user-time">{{ item.createTime }}</div>
            </div>
            <div class="page-box-problem">问题:{{ item.content }}</div>
            <div class="page-box-border"></div>
          </div>
          <div class="page-more-btn">
            <!-- 页码 -->
            <el-pagination :current-page="params.page" :page-sizes="[10, 20, 30, 40]" :page-size="params.pageSize"
              :total="params.totalCount" layout="total, sizes, prev, pager, next" @size-change="handleSizeChange"
              @current-change="handleCurrentChange" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <ExpertProblem class="problem" v-if="lsHoverIdx == 2"></ExpertProblem>
        <ExpertMyproblem class="myproblem" v-if="lsHoverIdx == 3"></ExpertMyproblem>
      </div>
    </div>
    <gateway-footer />
    <ToTopperComponent />
    <el-dialog class="login-dialog" :visible.sync="dialogVisible" width="580px" :show-close="false"
      :before-close="handleClose" @close="dialogClose" :close-on-click-modal="false">
      <div class="cancel-box">
        <img @click="dialogVisible = false" src="../../assets/img/gateway/expert-services/cancel.png" />
      </div>
      <div class="title">欢迎{{ loginTarget ? '登录' : '注册' }}</div>
      <div v-if="loginTarget">
        <el-form ref="loginForm" class="login-form" :model="loginForm" :rules="loginRules">
          <el-form-item key="loginUsername" label="账号" prop="userName">
            <el-input v-model="loginForm.userName" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item key="loginPassword" label="密码" prop="password">
            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码">
            </el-input>
          </el-form-item>
          <div class="goLogin-btn">
            <span @click="loginTarget = false">点击注册</span>
          </div>
        </el-form>
        <div class="register-btn" @click="loginRequest">登录</div>
        <div class="register-cancel">
          <span @click="dialogVisible = false">取消</span>
        </div>
        <div class="register-code-box">
          <span @click="phoneCodeLogin">小程序用户手机验证码登录</span>
        </div>
      </div>
      <div v-else>
        <el-form ref="registerForm" :model="registerForm" :rules="registerRules" autocomplete="off">
          <el-form-item key="registerPhone" label-width="140px" label="手机号" prop="phone" ref="phoneTestNum">
            <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item key="registerPhoneNum" label-width="140px" label="手机验证码" prop="smsCode">
            <el-input v-model="registerForm.smsCode" placeholder="请输入验证码">
              <template slot="append">
                <div @click="getTestCode" v-if="testNumTime == 0" class="phoneNum">
                  获取验证码
                </div>
                <div v-else class="TestTimeNum">
                  {{ testNumTime }}秒后重新获取
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item autocomplete="new-password" key="registerUsername" label-width="140px" label="用户名"
            prop="nickName">
            <el-input autocomplete="new-password" v-model="registerForm.nickName" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item autocomplete="new-password" key="registerPassword" label-width="140px" label="密码"
            prop="password">
            <el-input autocomplete="new-password" type="password" v-model="registerForm.password" placeholder="请输入密码">
            </el-input>
          </el-form-item>
          <el-form-item key="registerPassword2" label-width="140px" label="确认密码" prop="twoPassword">
            <el-input type="password" v-model="registerForm.twoPassword" placeholder="请确认密码"></el-input>
          </el-form-item>
          <div class="goLogin-btn">
            <span @click="loginTarget = true">已有账号,直接登录</span>
          </div>
        </el-form>
        <div class="register-btn" @click="registerRequest">确定按钮</div>
        <div class="register-cancel">
          <span @click="dialogVisible = false">取消</span>
        </div>
      </div>
    </el-dialog>
    <!-- 验证码登录Dialog -->
    <el-dialog class="login-dialog" :visible.sync="dialogVisible2" width="580px" :show-close="false"
      :before-close="beforeClose2" @close="dialogClose2" :close-on-click-modal="false">
      <div class="cancel-box">
        <img @click="dialogVisible2 = false" src="../../assets/img/gateway/expert-services/cancel.png" />
      </div>
      <div class="title">手机验证码登录</div>
      <div>
        <el-form ref="PhoneCodeForm" :model="PhoneCodeForm" :rules="PhoneCodeRules">
          <el-form-item key="registerPhone" label-width="140px" label="手机号" prop="phone" ref="CodePhoneTestNum">
            <el-input v-model="PhoneCodeForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item key="registerPhoneNum" label-width="140px" label="手机验证码" prop="smsCode">
            <el-input v-model="PhoneCodeForm.smsCode" placeholder="请输入验证码">
              <template slot="append">
                <div @click="PhoneGetTestCode" v-if="testNumTime == 0" class="phoneNum">
                  获取验证码
                </div>
                <div v-else class="TestTimeNum">
                  {{ testNumTime }}秒后重新获取
                </div>
              </template>
            </el-input>
          </el-form-item>
          <div class="goLogin-btn"></div>
        </el-form>
        <div class="register-btn" key="phoneCode" @click="PhoneCodeloginRequest">登录</div>
        <div class="register-cancel">
          <span @click="dialogVisible2 = false">取消</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { regTestPhone, RegPasswordContent } from '@/utils/rules'
import {
  getTestNum,
  registerUser,
  loginUser,
  getPageList,
  PhoneCodeLogin,
} from '@/views/gateway/modules/expert-services/expert-services.js'
import ExpertMyproblem from '@/components/expert-services/expert-myproblem'
import ExpertProblem from '@/components/expert-services/expert-problem'
import GatewayHeader from '../../components/gateway-header.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import { ExpertNav } from './modules/static'
import Vue from 'vue'
import {
  Input,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
} from 'element-ui'
Vue.use(Input)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Pagination)

export default {
  name: 'expertServices',
  components: {
    GatewayHeader,
    ExpertMyproblem,
    ExpertProblem,
    GatewayFooter,
    ToTopperComponent,
  },
  data() {
    //   const RegPasswordEnter = (rule, value, callback) => {
    // if (!value) {
    //   return callback(new Error('密码不能为空'))
    // }else if ((String(value).trim()).length < 6) {
    //   return callback(new Error('密码不能少于6个字符!'))
    // }else if (value!==this.registerForm.password) {
    //   return callback(new Error('两次密码输入不一致'))
    // }
    // else {
    //   callback()
    // }
    // }
    return {
      dialogVisible2: false, //验证码登录Dialog框
      userInfoData: {},
      // 验证码节流倒计时
      testNumTime: 0,
      // 登录注册页面判断
      loginTarget: false,
      // 登录注册dialog显示与否
      dialogVisible: false,
      bannerUrl: '',
      navLs: ExpertNav,
      // 请求参数
      lsHoverIdx: 1,
      dataList: [],
      // 分页查询params
      params: {
        page: 1,
        pageSize: 10,
        totalCount: 0,
        param: {
          title: '', //模糊搜索标题
        },
      },
      // 手机验证码登录表单
      PhoneCodeForm: {
        // 手机号码
        phone: '',
        // 验证码
        smsCode: '',
      },
      // 注册表单
      registerForm: {
        // 手机号码
        phone: '',
        // 验证码
        smsCode: '',
        // 用户名
        nickName: '',
        // 密码
        password: '',
        // 确认密码
        twoPassword: '',
      },
      // 注册表单规则验证
      registerRules: {
        phone: [{ required: true, validator: regTestPhone, trigger: 'blur' }],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        nickName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, validator: RegPasswordContent, trigger: 'blur' },
        ],
        twoPassword: [
          { required: true, validator: RegPasswordContent, trigger: 'blur' },
        ],
      },
      // 手机验证码登录表单规则验证
      PhoneCodeRules: {
        phone: [{ required: true, validator: regTestPhone, trigger: 'blur' }],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      // 登录表单
      loginForm: {
        userName: '',
        password: '',
      },
      // 登录表单规则验证
      loginRules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      timer: null,
    }
  },
  watch: {
    lsHoverIdx: function (newValue, oldValue) {
      if (newValue == 1 && oldValue !== 1) {
        this.getListData()
      }
    },
    loginTarget: function (newVal, oldVal) {
      if (newVal) {
        return this.initregisterForm()
      }
      this.$refs.loginForm.resetFields()
      this.initLoginForm()
    },
  },
  created() {
    this.$axios('/haojiang-portal/frontIndustrial/getBanner').then(
      (r) => (this.bannerUrl = r.data[0].url || '')
    )
  },
  beforeRouteEnter: (to, from, next) => {
    next()
  },
  mounted() {
    let data = JSON.parse(localStorage.getItem('userInfo'))
    this.userInfoData = data
    this.getListData()
    if (this.$route.query?.type === 'myProblem') {
      this.lsHoverIdx = 3
    }
  },
  computed: {
    loginName: {
      get: function () {
        const data = this.userInfoData
        return this.userInfoData?.userInfo?.nickName
      },
    },
  },
  methods: {
    // 手机验证码登录获取验证码
    PhoneGetTestCode() {
      console.log(this.$refs.CodePhoneTestNum.form)
      this.$refs.CodePhoneTestNum.form.validateField('phone', async (code) => {
        if (code) {
          return
        }
        const phone = this.PhoneCodeForm.phone
        let res = await getTestNum(phone)
        if (res.code !== '00000' || !res.data) {
          return this.$message.error('获取验证码过于频繁，请稍后再试')
        }
        // 以下为验证码节流逻辑
        this.testNumTime = 60
        var timer = setInterval(() => {
          this.testNumTime -= 1
          if (this.testNumTime == 0) {
            clearInterval(timer)
            return
          }
        }, 1000)
      })
    },
    PhoneCodeloginRequest() {
      this.$refs.PhoneCodeForm.validate((key) => {
        if (!key) return
        let param = {
          phone: this.PhoneCodeForm.phone,
          code: this.PhoneCodeForm.smsCode,
        }
        PhoneCodeLogin(param)
          .then((res) => {
            // 兼容用户名(必须-不然页面会无法刷新重定向)
            if (!res.data.userInfo.nickName) {
              res.data.userInfo.nickName = res.data.userInfo.userName
            }
            const data = res.data
            console.log(data);
            const sessionId = res.data.accessToken
            let strUserInfo = JSON.stringify(data)
            let sessionIdStr = JSON.stringify(sessionId)
            // 截取token前后字符
            sessionIdStr = sessionIdStr.slice(1, sessionIdStr.length - 1)
            localStorage.setItem('userInfo', strUserInfo)
            localStorage.setItem('sessionId', sessionIdStr)
            location.href = `./expertServices`
          })
          .catch((err) => {
            console.log(err)
            let str = String(err)
            str = str.replace('Error:', '')
            this.$message.error(str)
          })
      })
    },
    phoneCodeLogin() {
      this.dialogVisible = false
      this.dialogVisible2 = true
    },
    beforeClose2() {
      this.dialogVisible2 = false
    },
    // 手机验证码D登录ialog关闭初始化事件
    dialogClose2() {
      this.$refs.PhoneCodeForm.resetFields()
      this.PhoneCodeForm.phone = ''
      this.PhoneCodeForm.smsCode = ''
    },
    // 获取列表
    async getListData() {
      const res = await getPageList(this.params)
      this.dataList = res.data.list
      this.params.page = res.data.page
      this.params.pageSize = res.data.pageSize
      this.params.totalCount = res.data.totalCount
    },
    // pageSize改变触发事件
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getListData()
    },
    // page改变触发
    handleCurrentChange(page) {
      this.params.page = page
      this.getListData()
    },
    // 退出登录
    loginOut(key) {
      console.log('退出登录', key)
      localStorage.clear('userInfo')
      location.href = './expertServices'
    },
    dialogClose() {
      // 登录关闭初始化

      if (this.loginTarget) {
        this.$refs.loginForm.resetFields()
        this.loginForm.userName = ''
        this.loginForm.password = ''
        return
      }
      // 注册关闭初始化
      this.$refs.registerForm.resetFields()
      this.registerForm.phone = ''
      this.registerForm.smsCode = ''
      this.registerForm.nickName = ''
      this.registerForm.password = ''
      this.registerForm.twoPassword = ''
    },
    // 获取验证码
    getTestCode() {
      this.$refs.phoneTestNum.form.validateField('phone', async (code) => {
        if (code) {
          return
        }
        const phone = this.registerForm.phone
        let res = await getTestNum(phone)
        if (res.code !== '00000' || !res.data) {
          return this.$message.error('获取验证码过于频繁，请稍后再试')
        }
        // 以下为验证码节流逻辑
        this.testNumTime = 60
        var timer = setInterval(() => {
          this.testNumTime -= 1
          if (this.testNumTime == 0) {
            clearInterval(timer)
            return
          }
        }, 1000)
      })
    },
    // 登录请求
    loginRequest() {
      this.$refs.loginForm.validate((key) => {
        if (!key) return
        loginUser(this.loginForm)
          .then((res) => {
            // 兼容用户名(必须-不然页面会无法存储登录状态并刷新重定向)
            if (!res.data.userInfo.nickName) {
              res.data.userInfo.nickName = res.data.userInfo.userName
            }
            const data = res.data
            const sessionId = res.data.accessToken
            let strUserInfo = JSON.stringify(data)
            let sessionIdStr = JSON.stringify(sessionId)
            // 截取token前后字符
            sessionIdStr = sessionIdStr.slice(1, sessionIdStr.length - 1)
            localStorage.setItem('userInfo', strUserInfo)
            localStorage.setItem('sessionId', sessionIdStr)
            location.href = `./expertServices`
          })
          .catch((err) => {
            let str = String(err)
            str = str.replace('Error:', '')
            return this.$message.error(str)
          })
      })
    },
    // 注册请求
    registerRequest() {
      this.$refs.registerForm.validate((key) => {
        if (!key) return
        registerUser(this.registerForm)
          .then((res) => {
            if (res.code !== '00000') {
              return this.$message.error('注册失败')
            }
            this.$message.success('注册成功')
            this.loginTarget = true
          })
          .catch((err) => {
            let str = String(err)
            str = str.replace('Error:', '')
            return this.$message.error(str)
          })
      })
    },
    // 登录表单初始化
    initLoginForm() {
      this.loginForm.userName = ''
      this.loginForm.password = ''
    },
    // 注册表单初始化
    initregisterForm() {
      this.registerForm.phone = ''
      this.registerForm.smsCode = ''
      this.registerForm.nickName = ''
      this.registerForm.password = ''
      this.registerForm.twoPassword = ''
    },
    // 登录事件
    loginClick() {
      this.dialogVisible = true
      this.loginTarget = true
    },
    // 注册事件
    registerClick() {
      this.dialogVisible = true
      this.loginTarget = false
    },
    // dialog关闭触发监听事件
    handleClose() {
      this.dialogVisible = false
    },
    // 加载更多
    pageMoreClick() {
      console.log('加载更多')
    },
    clickItem(idx) {
      if ((idx == 1 || idx == 2) && !this.loginName) {
        this.$message.error({
          message: '登录后才能提问或查看我的提问',
          type: 'info',
        })
        return
      }
      this.lsHoverIdx = idx + 1
      sessionStorage.setItem('firstNav', this.navLs[idx].name)
      this.params.searchWord = ''
    },
    pageBoxClick(id) {
      this.$router.push('/expertDetail?id=' + id)
    },
  },
}
</script>
<style lang="less" scoped>
@import './expertServices.less';
@import './sub-pages.less';

</style>
