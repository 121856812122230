<template>
  <div class="gateway sub-pages img-dialog">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />
    <div class="sub-pages-banner">
      <div class="img-box">
        <img :src="bannerUrl" alt="" />
      </div>
    </div>
    <!-- 路由导航 -->
    <div class="main-content gateway-module">
      <div class="module-title">{{ title ? title : '' }}</div>
      <div class="you-position">
        <i><img src="./modules/adress.png" alt="" /></i>
        <p>您的位置:产业园服务>专家服务>详情</p>
        <span @click="gobackpage">
          返回上级
          <img src="../../assets/img/gateway/icon-arrow-gray.png" alt="" />
          <img class="img-active" src="../../assets/img/gateway/icon-arrow-active.png" alt="" />
        </span>
      </div>
      <div class="detail-box-content">
        <div class="head-title">
          <div class="head-img">
            <img src="../../assets/img/gateway/expert-services/yonghu.png" alt="" />
          </div>
          <span class="head-username">{{ detailForm.nickName }}
            <div class="head-username-time">{{ detailForm.createTime }}</div>
          </span>
        </div>
        <!-- 问题部分 -->
        <div class="content-box">
          <div class="content-box-title">「问题」</div>
          <div class="content-box-text">
            {{ detailForm.content }}
          </div>
          <div class="content-box-title" v-if="imgDataList.length">
            「图片」
          </div>
          <div class="content-box-imgBox">
            <div class="img" @mouseenter="imgEnterMask(idx)" @mouseleave="imgIndex = null" :key="item"
              v-for="(item, idx) in imgDataList">
              <div class="mask-img" v-show="idx == imgIndex">
                <div @click="handlePictureCardPreview(item)" class="mask-icon el-icon-zoom-in"></div>
              </div>
              <img :src="item" alt="" />
            </div>
          </div>
          <div class="plobrem-box-border"></div>
        </div>
        <!-- 回复部分 -->
        <div :class="[
          item.roleFlag == 0 ? 'reply-user-box' : 'reply-box',
          'replay-class-box',
        ]" :key="idx" v-for="(item, idx) in replyData">
          <!-- 回复标题 -->
          <div class="reply-title">
            <div class="head-img">
              <!-- 用户头像 -->
              <img v-show="item.roleFlag == 0" src="../../assets/img/gateway/expert-services/yonghu.png" alt="" />
              <!-- 专家头像 -->
              <img v-show="item.roleFlag == 2" src="../../assets/img/gateway/expert-services/zhuanjia.png" alt="" />
            </div>
            <span class="head-username">{{ item.nickName }}
              <div class="head-icon" v-show="item.roleFlag == 2">专家</div>
              <div class="head-username-time">{{ item.createTime }}</div>
            </span>
          </div>
          <div class="reply-small-title">「回复」</div>
          <div class="reply-content">
            {{ item.content }}
          </div>
          <div class="reply-border"></div>
        </div>
        <!-- 专家回复 -->
        <div class="reply-box" v-if="userInfo.roleFlag == 2">
          <!-- 回复标题 -->
          <div class="reply-title">
            <div class="head-img">
              <img src="../../assets/img/gateway/expert-services/zhuanjia.png" alt="" />
            </div>
            <span class="head-username">{{ userInfo.nickName }}</span>
          </div>
          <div class="reply-small-title">「回复」</div>
          <div class="reply-content">
            <div class="content-reply">
              <el-input type="textarea" maxlength="300" show-word-limit :rows="20" placeholder="输入文本内容,不多于300字~"
                v-model="updateForm.content" />
            </div>
          </div>
          <div class="content-reply-btn">
            <span @click="updateProblem">保存</span>
          </div>
        </div>
        <!-- 用户回复 -->
        <div class="reply-user-box" v-if="userInfo.roleFlag == 0 && userInfo.id == detailForm.userId">
          <div class="reply-title">
            <div class="head-img">
              <img src="../../assets/img/gateway/expert-services/yonghu.png" alt="" />
            </div>
            <span class="head-username">{{ userInfo.nickName }}</span>
          </div>
          <div class="reply-small-title">「回复」</div>
          <div class="reply-content">
            <div class="content-reply">
              <el-input type="textarea" maxlength="300" show-word-limit :rows="20" placeholder="输入文本内容,不多于300字~"
                v-model="updateForm.content" />
            </div>
          </div>
          <div class="content-reply-btn">
            <span @click="updateProblem">保存</span>
          </div>
        </div>
      </div>
    </div>
    <ToTopperComponent />
    <gateway-footer />
    <el-dialog class="imgDialog" :visible.sync="CheckdialogVisible">
      <div class="img-dialog-header">
        <img @click="CheckdialogVisible = false" src="../../assets/img/gateway/expert-services/cancel.png" alt="" />
      </div>
      <div class="img-dialog-body">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getIdInfo,
  editProblem,
} from '@/views/gateway/modules/expert-services/expert-services.js'
import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ImgDetailLayout from '../../components/img-detail-layout.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
import ColumnNewLayout from '../../components/column-new-layout.vue'
import * as _static from './modules/static'
import { utils as _utils } from './modules/public-utils'

import Vue from 'vue'
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Table,
  TableColumn,
  Pagination,
  DatePicker,
} from 'element-ui'
Vue.use(Form)
  .use(FormItem)
  .use(Input)
  .use(Select)
  .use(Option)
  .use(Table)
  .use(TableColumn)
  .use(Pagination)
  .use(DatePicker)

export default {
  name: 'sub-pages',
  data() {
    return {
      imgIndex: null,
      dialogImageUrl: '', //查看图片的路径
      CheckdialogVisible: false,
      title: '',
      id: '', //查询id
      bannerUrl: '',
      userInfo: JSON.parse(localStorage.getItem('userInfo'))?.userInfo || {},
      imgDataList: [],
      dialogVRVisible: false,
      // 提问详情数据
      detailForm: {},
      // 回复数组
      replyData: [],
      // 编辑提问
      updateForm: {
        // 内容
        content: '',
      },
    }
  },

  components: {
    GatewayHeader,
    GatewayFooter,
    ImgDetailLayout,
    ColumnNewLayout,
    ToTopperComponent,
  },
  computed: {},
  beforeMount() { },
  mounted() {
    const id = this.$route.query.id
    this.id = id
    this.getIdInfo(id)
  },
  methods: {
    // 移入图片显示遮罩层
    imgEnterMask(idx) {
      console.log(idx)
      this.imgIndex = idx
    },
    // 查看图片dialog框显示
    handlePictureCardPreview(url) {
      console.log(url)
      this.dialogImageUrl = url
      this.CheckdialogVisible = true
    },
    // 保存编辑提问
    async updateProblem() {
      const params = {
        questionId: this.$route.query.id,
        userId: this.userInfo.id,
        content: this.updateForm.content,
      }
      const num = String(params.content).trim().length
      if (!num) {
        return this.$message.error('回复不能为空!')
      } else if (num > 300) {
        return this.$message.error('回复大于300个字!')
      }
      const res = await editProblem(params)
      console.log(res)
      if (res.code !== '00000') return this.$message.error('保存失败')
      location.href = `./expertDetail?id=${this.id}`
    },
    // 初始化详情页根据id获取数据
    async getIdInfo(id) {
      let res = await getIdInfo(id)
      if (res.code !== '00000') return
      // 标题提级
      this.title = res.data.title
      const data = res.data['questionUserVOList']
      for (let i = 0; i < data.length - 1; i++) {
        this.replyData.push(data[i])
      }
      const responseData = data[data.length - 1]
      if (responseData.urlOne) {
        this.imgDataList.push(responseData?.urlOne)
      }
      if (responseData.urlTwo) {
        this.imgDataList.push(responseData?.urlTwo)
      }
      if (responseData.urlThree) {
        this.imgDataList.push(responseData?.urlThree)
      }
      this.detailForm = responseData
    },
    // 返回上级
    gobackpage() {
      if (this.$route.query?.type === 'myProblem') {
        return this.$router.push('/expertServices?type=myProblem')
      }
      this.$router.push('/expertServices')
    },
  },
  created() {
    this.$axios('/haojiang-portal/frontIndustrial/getBanner').then(
      (r) => (this.bannerUrl = r.data[0].url || '')
    )
  },
  destroyed() { },
  // 数据监听
  watch: {},
}
</script>
<style lang="less" scoped>
@import './sub-pages.less';
@import './expert-detail.less';

</style>

