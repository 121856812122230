/*
 * @Descripttion:手机号格式校验(element)
 * @Author: zcy
 * @Date: 2021-11-30 15:08:54
 * @LastEditors: zcy
 * @LastEditTime: 2021-12-10 11:11:20
 */
export const regTestPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'))
  }
  const reg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(18[0-9]{1})|(14[0-9]{1})|(17[0-9]{1})|(19[0-9]{1}))+\d{8})$/
  if (value.length < 11) {
    callback(new Error('手机号必须是11位'))
  } else if (!reg.test(value)) {
    callback(new Error('手机号格式不正确'))
  } else {
    callback()
  }
}

/*
 * @Descripttion:专家服务标题校验(element)
 * @Author: zcy
 * @Date: 2021-11-30 15:08:54
 * @LastEditors: zcy
 * @LastEditTime: 2021-11-30 15:09:59
 */
export const RegExpertTitle = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('标题不能为空'))
  }else if (value.length > 200) {
    return callback(new Error('标题不能超过200个字符!'))
  } else {
    callback()
  }
}

/*
 * @Descripttion:专家服务内容校验(element)
 * @Author: zcy
 * @Date: 2021-11-30 15:08:54
 * @LastEditors: zcy
 * @LastEditTime: 2021-11-30 15:09:59
 */
export const RegExpertContent = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('内容不能为空'))
  }else if (value.length > 300) {
    return callback(new Error('内容不能超过300个字符!'))
  }
  else {
    callback()
  }
}


/*
 * @Descripttion:专家服务密码输入校验(element)
 * @Author: zcy
 * @Date: 2021-11-30 15:08:54
 * @LastEditors: zcy
 * @LastEditTime: 2021-11-30 15:09:59
 */
export const RegPasswordContent = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('密码不能为空'))
  }else if ((String(value).trim()).length < 6) {
    return callback(new Error('密码不能少于6个字符!'))
  }
  else {
    callback()
  }
}

