<!--
 * @Descripttion:我的提问
 * @Author: zcy
 * @Date: 2021-11-25 13:53:19
 * @LastEditors: zcy
 * @LastEditTime: 2022-01-19 16:30:01
-->
<template>
  <div class="gateway myproblem_box">
    <!-- 左侧导航栏 -->
    <div class="left-box">
      <div
        :class="['left-content-box', idx == leftNavNum ? 'on-content-box' : '']"
        v-for="(item, idx) in leftNavList"
        :key="idx"
        @click="leftClick(idx)"
        @mouseenter="leftEnter(idx)"
        @mouseleave="leftLeave"
      >
        {{ item.name }}
      </div>
    </div>
    <!-- 右侧内容 -->
    <div class="right-box">
      <!-- 我的提问列表 -->
      <div v-if="rightNum == 0" class="sub-content-page">
        <div
          class="page-box"
          :key="index"
          v-for="(item, index) in myProbleList"
          @click="pageBoxClick(item)"
        >
          <div class="page-box-title">
            {{ item.title }}
          </div>
          <div class="page-box-user">
            <div class="user-text">
              用户:{{ item.nickName
              }}<span @click.stop="pageRemove(item)">删除</span>
            </div>
            <div class="user-time">{{ item.time }}</div>
          </div>
          <div class="page-box-problem">问题:{{ item.content }}</div>
          <div class="page-box-border"></div>
        </div>
        <el-pagination
          :current-page="params.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="params.pageSize"
          :total="params.totalCount"
          layout="total, sizes, prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
      <!-- 个人资料 -->
      <div class="personal-data" v-if="rightNum == 1">
        <h1>个人资料</h1>
        <el-form
          ref="problemForm"
          :rules="rules"
          :model="userForm"
          label-width="80px"
        >
          <el-form-item label="用户名" prop="username">
            <!-- :validate-event="false" -->
            <el-input :disabled="true" v-model="userForm.username"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              :disabled="true"
              type="input"
              v-model="userForm.phone"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 安全设置 -->
      <div class="security-setting" v-if="rightNum == 2">
        <h1>安全设置</h1>
        <div class="userbox">
          <h2>账号密码</h2>
          <span>
            <p>当前密码强度&nbsp;:&nbsp;{{ passwordStroage }}</p>
            <p @click="ModifyClick('password')" class="Modify">修改</p>
          </span>
        </div>
        <div class="phonebox">
          <h2>手机号码</h2>
          <span>
            <p>已绑定手机&nbsp;:&nbsp;{{ securityPhone }}</p>
            <p @click="ModifyClick('phone')" class="Modify">修改</p>
          </span>
        </div>
      </div>
    </div>
    <!-- 删除我的提问dialog框 -->
    <el-dialog
      :close-on-click-modal="false"
      class="remove-dialog"
      :visible.sync="dialogVisible1"
      width="500px"
      height="335px"
      :before-close="handleClose1"
    >
      <div class="waring-box">
        <div class="cancel-box">
          <img
            @click="dialogVisible1 = false"
            src="../../assets/img/gateway/expert-services/cancel.png"
            alt=""
          />
        </div>
        <div class="waring-header">
          <div class="head-img">
            <img
              src="../../assets/img/gateway/expert-services/waring.png"
              alt=""
            />
          </div>
          <div class="head-text">您确定要删除吗?</div>
        </div>
        <div class="waring-btn-box">
          <div class="waring-btn1" @click="EnterRemove">确定</div>
          <div class="waring-btn2" @click="dialogVisible1 = false">取消</div>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑账号信息dialog框 -->
    <el-dialog
      :close-on-click-modal="false"
      class="update-dialog"
      :visible.sync="dialogVisible2"
      width="500px"
      :before-close="() => (dialogVisible2 = false)"
    >
      <div class="cancel-box">
        <div class="title">
          <div class="icon"></div>
          <div class="text">
            修改{{ updateUserInfo === 'password' ? '密码' : '手机号' }}
          </div>
        </div>
        <img
          @click="dialogVisible2 = false"
          src="../../assets/img/gateway/expert-services/cancel.png"
          alt=""
        />
      </div>
      <div class="password-box" v-if="updateUserInfo === 'password'">
        <el-form
          ref="passwordForm"
          class="password-form"
          :model="passwordForm"
          :rules="passwordRules"
        >
          <el-form-item
            label-width="100px"
            key="passwordUsername"
            label="新密码"
            prop="newPassword"
          >
            <el-input
              type="password"
              v-model="passwordForm.newPassword"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="100px"
            key="passwordPassword"
            label="确认密码"
            prop="confirmPassword"
          >
            <el-input
              type="password"
              v-model="passwordForm.confirmPassword"
              placeholder="请确认密码"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn-box">
          <div class="updateClick" @click="passwordClick">确定</div>
          <div class="cancelClick" @click="dialogVisible2 = false">取消</div>
        </div>
      </div>
      <div class="phone-box password-box" v-if="updateUserInfo === 'phone'">
        <el-form
          ref="phoneForm"
          class="password-form"
          :model="phoneForm"
          :rules="phoneRules"
        >
          <el-form-item
            label-width="100px"
            ref="smsCodephone"
            key="phone"
            label="手机号码"
            prop="phone"
          >
            <el-input
              v-model="phoneForm.phone"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="100px"
            key="phoneNum"
            label="验证码"
            prop="code"
          >
            <el-input v-model="phoneForm.code" placeholder="请输入验证码">
              <template slot="append">
                <div
                  class="phoneNum"
                  v-if="!testNumTime"
                  @click="getPhoneSmsCode"
                >
                  获取验证码
                </div>
                <div v-else class="TestTimeNum">
                  {{ testNumTime }}秒后重新获取
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn-box">
          <div class="updateClick" @click="phoneCodeClick">确定</div>
          <div class="cancelClick" @click="dialogVisible2 = false">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { regTestPhone,RegPasswordContent } from '@/utils/rules'
import {
  myProblemPage,
  deleteProblem,
  updatePassword,
  updatePhone,
  getTestNum,
} from '@/views/gateway/modules/expert-services/expert-services.js'
export default {
  name: 'expertProblem',
  components: {},
  data() {
    return {
      testNumTime: 0,
      // 删除问题的id
      removeId: '',
      params: {
        page: 1,
        pageSize: 10,
        totalCount: 0,
        param: {
          id: JSON.parse(localStorage.getItem('userInfo')).userInfo.id,
        },
      },
      // 删除提问dialog
      dialogVisible1: false,
      // 编辑信息dialog
      dialogVisible2: false,
      updateUserInfo: '',
      // 持久高亮
      leftOldNum: 0,
      leftNavNum: 0,
      // right内容显示
      // 安全设置密码强度显示
      passwordStroage: '低',
      // 安全设置手机号码显示
      securityPhone: JSON.parse(localStorage.getItem('userInfo')).userInfo
        .phone,
      rightNum: 0,
      // 左侧导航布局数组
      leftNavList: [
        { name: '我的提问' },
        { name: '个人资料' },
        { name: '安全设置' },
      ],
      // 我的提问数据数组
      myProbleList: [],
      // 个人资料表单数据
      userForm: {
        username: JSON.parse(localStorage.getItem('userInfo')).userInfo
          .nickName,
        phone: JSON.parse(localStorage.getItem('userInfo')).userInfo.phone,
      },
      // form规则
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'change' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
        ],
      },
      // 修改密码form表单
      passwordForm: {
        // 用户id
        id: '',
        // 确认密码
        confirmPassword: '',
        // 新密码
        newPassword: '',
      },
      // 修改密码form表单验证
      passwordRules: {
        newPassword: [
          { required: true, validator:RegPasswordContent, trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, validator:RegPasswordContent, trigger: 'blur' },
        ],
      },
      // 修改手机号form表单
      phoneForm: {
        // 验证码
        code: '',
        // 手机号码
        phone: '',
        userId: '',
      },
      // 修改手机号form表单验证
      phoneRules: {
        phone: [{ required: true, validator: regTestPhone, trigger: 'change' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'change' }],
      },
    }
  },
  watch: {},
  created() {},
  mounted() {
    setTimeout(() => {})
    this.getPageList(this.params)
  },
  methods: {
    // 确定修改手机号
    phoneCodeClick() {
      this.$refs.phoneForm.validate((key) => {
        if (key) {
          this.phoneForm.userId = JSON.parse(
            localStorage.getItem('userInfo')
          ).userInfo.id
          updatePhone(this.phoneForm)
            .then((res) => {
              console.log(res)
              if (res.code !== '00000') {
                return this.$message.error('修改手机号失败')
              }
              this.$message.success('修改手机号码成功')
              this.dialogVisible2 = false
              // 重新修改浏览器存储
              const obj = JSON.parse(localStorage.getItem('userInfo'))
              obj.userInfo.phone = this.phoneForm.phone
              localStorage.setItem('userInfo', JSON.stringify(obj))
              // input框显示号码
              this.userForm.phone = this.phoneForm.phone
              this.securityPhone = this.phoneForm.phone
              // 初始化phoneForm数据
              this.phoneForm.phone = ''
              this.phoneForm.code = ''
            })
            .catch((err) => {
              let str = String(err)
              str = str.replace('Error:', '')
              return this.$message.error(str)
            })
        }
      })
    },
    // 确认修改密码
    passwordClick() {
      this.$refs.passwordForm.validate((key) => {
        if (key) {
          this.passwordForm.id = JSON.parse(
            localStorage.getItem('userInfo')
          ).userInfo.id
          if (
            !(
              this.passwordForm.newPassword ===
              this.passwordForm.confirmPassword
            )
          ) {
            return this.$message.error('两次密码输入不一致')
          }
          updatePassword(this.passwordForm)
            .then((res) => {
              if (res.code !== '00000') {
                return this.$message.error('修改密码失败')
              }
              this.$message.success('修改密码成功')
              this.dialogVisible2 = false
              this.passwordForm.newPassword = ''
              this.passwordForm.confirmPassword = ''
            })
            .catch((err) => {
              let str = String(err)
              str = str.replace('Error:', '')
              return this.$message.error(str)
            })
        }
      })
    },
    // 安全设置-获取验证码
    getPhoneSmsCode() {
      console.log('获取验证码')
      this.$refs.smsCodephone.form.validateField('phone', async (code) => {
        if (code) {
          return
        }
        const phone = this.phoneForm.phone
        let res = await getTestNum(phone)
        if (res.code !== '00000' || !res.data) {
          return this.$message.error('获取验证码过于频繁，请稍后再试')
        }
        // 以下为验证码节流逻辑
        this.testNumTime = 60
        var timer = setInterval(() => {
          this.testNumTime -= 1
          if (this.testNumTime == 0) {
            clearInterval(timer)
            return
          }
        }, 1000)
      })
    },
    // 确定删除提问
    EnterRemove() {
      // 点击获取验证码
      deleteProblem(this.removeId)
        .then((res) => {
          if (res.code !== '00000') return this.$message.error('删除失败')
          this.$message.success('删除成功')
          this.getPageList(this.params)
          this.dialogVisible1 = false
        })
        .catch((err) => {
          let str = String(err)
          str = str.replace('Error:', '')
          return this.$message.error(str)
        })
    },
    // 获取我的提问列表
    getPageList(params) {
      myProblemPage(params).then((res) => {
        this.myProbleList = res.data.list
        this.params.page = res.data.page
        this.params.pageSize = res.data.pageSize
        this.params.totalCount = res.data.totalCount
      })
    },
    // 条数改变
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getPageList(this.params)
    },
    // 当前页改变
    handleCurrentChange(page) {
      this.params.page = page
      this.getPageList(this.params)
    },
    // 删除提问dialog关闭事件监听
    handleClose1() {
      this.dialogVisible1 = false
    },
    // 修改信息dialog关闭事件监听
    handleClose2() {
      this.dialogVisible1 = false
    },
    // 删除我的提问
    pageRemove(data) {
      this.dialogVisible1 = true
      this.removeId = data.id
    },
    // 修改账号密码
    ModifyClick(type) {
      if (type === 'phone') {
        setTimeout(() => {
          this.$refs.phoneForm.resetFields()
        })
      }
      if (type === 'password') {
        setTimeout(() => {
          this.$refs.passwordForm.resetFields()
        })
      }
      this.dialogVisible2 = true
      this.updateUserInfo = type
    },
    leftClick(idx) {
      this.leftOldNum = idx
      this.rightNum = idx
    },
    leftEnter(idx) {
      this.leftNavNum = idx
    },
    leftLeave() {
      this.leftNavNum = this.leftOldNum
    },
    pageBoxClick(data) {
      this.$router.push('/expertDetail?id=' + data.id+'&type=myProblem')
    },
    pageMoreClick() {
      console.log('我的提问列表加载更多')
    },
  },
}
</script>
<style lang="less" scoped>
@import '../../views/gateway/sub-pages.less';
::v-deep.myproblem_box {
  display: flex;
  width: 100%;

  .remove-dialog {
    .el-dialog__header {
      display: none;
    }
    .waring-box {
      .cancel-box {
        cursor: pointer;
        box-sizing: border-box;
        padding-top: 20px;
        padding-right: 20px;
        justify-content: flex-end;
        display: flex;
      }
      .waring-header {
        box-sizing: border-box;
        display: flex;
        line-height: 28px;
        padding-left: 160px;
        padding-top: 30px;
        margin-bottom: 67px;

        .head-img {
          width: 27px;
          height: 27px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .head-text {
          margin-left: 10px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #646c7d;
        }
      }

      .waring-btn-box {
        padding: 0px 66px;
        display: flex;
        justify-content: space-between;

        div {
          width: 160px;
          height: 46px;
          margin-bottom: 83px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: center;
          line-height: 46px;
          border-radius: 6px;
        }

        .waring-btn1 {
          cursor: pointer;
          color: #ffff;
          background: #3d62ff;
        }

        .waring-btn2 {
          background: #e6e7ea;
          color: #7d7d7d;
          cursor: pointer;
        }
      }
    }
  }

  .update-dialog {
    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      height: 350px;
    }

    .phoneNum {
      cursor: pointer;
    }

    .TestTimeNum {
      cursor: not-allowed;
    }
    .cancel-box {
      cursor: pointer;
      box-sizing: border-box;
      padding-top: 20px;
      padding-right: 20px;
      justify-content: space-between;
      display: flex;

      .title {
        display: flex;
        padding-left: 31px;

        .icon {
          margin-top: 2px;
          box-sizing: border-box;
          margin-right: 12px;
          width: 4px;
          height: 14px;
          background: #1b1e2a;
        }

        .text {
          line-height: 18px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1b1e2a;
        }
      }
    }

    .password-box {
      .password-form {
        margin-top: 58px;
        margin-bottom: 60px;
      }

      .btn-box {
        display: flex;
        div {
          width: 160px;
          height: 46px;
          background: pink;
        }

        .updateClick {
          cursor: pointer;
          line-height: 46px;
          text-align: center;
          margin-left: 66px;
          background: #3d62ff;
          border-radius: 6px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;

          &:hover {
            background: #3659f1;
          }
        }

        .cancelClick {
          cursor: pointer;
          text-align: center;
          line-height: 46px;
          margin-left: 49px;
          background: #e6e7ea;
          border-radius: 6px;
          border-radius: 6px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7d7d7d;
        }
      }

      .el-form-item {
        padding-left: 35px;
        box-sizing: border-box;
        padding-right: 70px;
      }
      .el-dialog .el-dialog__header {
        display: none;
      }

      .el-form-item .el-form-item__label {
        text-align: right !important;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #8e8c8c;
        padding-right: 15px;
      }

      .el-form-item.is-required .el-form-item__label::after {
        display: none;
      }

      .el-form-item.is-required .el-form-item__label::before {
        position: relative;
        content: '';
        margin-right: 6px;
        margin-bottom: 3px;
        display: inline-block !important;
        width: 4px;
        height: 4px;
        background: #ff192f;
        border-radius: 50%;
        display: none;
      }

      // 清除element的label伪元素
      .el-form-item .el-form-item__label::before {
        display: none;
      }
    }

    .phone-box {
      .el-input-group__append {
        span {
          cursor: pointer;

          &:hover {
            color: #2951fd;
          }
        }
      }
    }
  }
  .left-box {
    width: 242px;

    .left-content-box {
      width: 100%;
      height: 64px;
      line-height: 64px;
      padding-left: 27px;
      box-sizing: border-box;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666870;
      cursor: pointer;
    }

    .on-content-box {
      background: #eef1f8;
      color: #2951fd;
      border-right: 4px solid #476aff;
    }
  }

  .right-box {
    flex: 1;
    width: 242px;
    padding-left: 87px;

    // 专家服务列表样式
    .sub-content-page {
      width: 100%;

      // 清除第一个盒子的默认样式
      .page-box:first-child {
        margin-top: 0 !important;
      }

      .page-box {
        margin-top: 96px;
        box-sizing: border-box;
        width: 100%;
        cursor: pointer;

        // 标题
        .page-box-title {
          position: relative;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1b1e2a;
        }

        // 用户
        .page-box-user {
          position: relative;
          display: flex;
          margin-top: 23px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666870;

          .user-text {
            left: 0;
            span {
              &:hover {
                color: #2951fd;
              }
              position: absolute;
              right: 0;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666870;
              line-height: 24px;
            }
          }

          .user-time {
            position: absolute;
            right: 0;
          }
        }

        // 问题
        .page-box-problem {
          margin-top: 13px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666870;
        }

        // 边框样式
        .page-box-border {
          padding-top: 47px;
          border-bottom: 1px solid #e7e7e7;
        }
      }

      .page-more-btn {
        width: 100%;
        position: relative;
        margin-top: 63px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-align: center;
        color: #1b1e2a;

        .btn-box {
          margin-top: 25px;
          color: #1b1e2a;
          font-family: 'Microsoft YaHei';
          font-size: 18px;
          font-weight: 400;
          cursor: pointer;

          i {
            display: block;
            width: 10px;
            height: 18px;
            margin: 0 auto;
            margin-top: 8px;
            background: center center / 100% 100%
              url('../../assets/img/large-screen/icon-swiper-right.png');
            transform: rotate(90deg);
          }
        }

        div {
          width: 100px;
          margin: 0 auto;
          cursor: pointer;
          // display: inline-block;
        }
      }
    }

    // 个人资料样式
    .personal-data {
      width: 100%;
      h1 {
        margin-bottom: 59px;
      }

      .el-form-item.is-required .el-form-item__label:after {
        content: '';
        display: block !important;
        text-align: center;
        line-height: 4px;
        width: 4px;
        top: 8px;
        height: 4px;
        left: -6px;
        background: #ff192f;
        border-radius: 50%;
        // display: none;
      }

      .el-input input {
        width: 356px;
        height: 38px;
        background: #f7f7f7;
        border-radius: 6px;
        border: 1px solid #dddddd;
      }

      // 清除element的label伪元素
      .el-form-item .el-form-item__label::before {
        display: none;
      }
    }

    // 安全设置样式
    .security-setting {
      width: 100%;

      h1 {
        margin-bottom: 59px;
      }

      // 账号密码
      .userbox {
        h2 {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1b1e2a;
        }
        span {
          display: flex;
          margin-top: 22px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666870;
          margin-bottom: 15px;

          .Modify {
            cursor: pointer;
            position: absolute;
            right: 0;
            &:hover {
              color: #2951fd;
            }
          }
        }
        border-bottom: 1px solid #e7e7e7;
      }
      // 手机号码
      .phonebox {
        margin-top: 39px;
        border-bottom: 1px solid #e7e7e7;

        h2 {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1b1e2a;
        }
        span {
          display: flex;
          margin-top: 22px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666870;
          margin-bottom: 15px;

          .Modify {
            cursor: pointer;
            position: absolute;
            right: 0;
            &:hover {
              color: #2951fd;
            }
          }
        }
      }
    }
  }
}
</style>
