<template>
  <div class="gateway news-center">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="news-center-banner">
      <div class="img-box">
        <img v-if="bannerUrl" :src="bannerUrl" alt="" />
      </div>
    </div>

    <div class="main-content gateway-module">
      <template v-if="isDetail">
        <div class="module-title" v-if="dataList.list[itemKey] && dataList.list[itemKey].title">
          {{ dataList.list[itemKey].title }}
        </div>
        <div class="you-position">
          <i><img src="./modules/adress.png" alt="" /></i>
          <p>
            您的位置:新闻中心{{
            `${firstNavName ? '>' + firstNavName : ''}`
            }}>详情
          </p>
          <span @click="gobackpage">
            返回上级
            <img src="../../assets/img/gateway/icon-arrow-gray.png" alt="" />
            <img class="img-active" src="../../assets/img/gateway/icon-arrow-active.png" alt="" />
          </span>
        </div>
      </template>
      <div class="module-content" v-show="!isDetail">
        <div class="sub-nav-box">
          <li v-for="(ele, idx) in navls" :class="['ls-item', { active: idx === lsHoverIdx }]" :key="idx"
            @click="clickSubNav(idx)">
            {{ ele }}
          </li>
        </div>
        <div class="ls-box" v-loading="isLoading">
          <template v-if="lsHoverIdx === 2">
            <li :class="['ls-item']" v-for="(item, id) in dataList.list" :key="id"
              @click="clickItem({ ...item, key: id })">
              <p class="line-clamp_1">{{ item.title }}</p>
              <i>{{ item.createTime && item.createTime.split(' ')[0] }}</i>
              <span>
                查看详情
                <img src="../../assets/img/gateway/icon-arrow-gray.png" alt="" />
                <img class="img-active" src="../../assets/img/gateway/icon-arrow-active.png" alt="" />
              </span>
            </li>
          </template>
          <div class="column-new-layout" :key="Math.random()" v-else>
            <ColumnNewLayout :dataList="dataList.list" @clickChildItem="clickItem" />

            <div @click="loadMoreData" v-show="
              dataList.list.length && dataList.totalPage > dataList.page
            " class="more-txt">
              查看更多<i></i>
            </div>
          </div>
        </div>
      </div>
      <div class="module-content news-detail-content" v-loading="isLoading" v-if="isDetail">
        <!-- 详情 -->
        <div class="item-detail">
          <div v-html="dataList.list[itemKey] && dataList.list[itemKey].content"></div>
        </div>
      </div>
    </div>

    <gateway-footer />
    <ToTopperComponent />
  </div>
</template>
<script>
let nextPage = ''
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ColumnNewLayout from '../../components/column-new-layout.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'

export default {
  name: 'news-center',
  data() {
    return {
      isLoading: true,
      isSecondLevel: false,

      pageTitle: '', // 中间大标题
      firstNavName: '', // 导航栏第一个
      lastNavName: '', // 最后一级的 位置
      isDetail: false,
      dataList: { list: [], page: 1, pageSize: 6, totalCount: 0, totalPage: 0 },
      navls: ['新闻动态', '领导关怀', '通知公告'],
      lsHoverIdx: 0,
      bannerUrl: '',
      itemKey: 0,
      nextPage: '',
    }
  },
  computed: {},
  methods: {
    loadMoreData() {
      this.dataList.page++
      this.loadData()
    },
    gobackpage() {
      if (nextPage) {
        this.$router.replace(nextPage)
        return
      }
      this.$router.push(`/newsCenter?type=${this.lsHoverIdx}&isDetail=0`)
      this.isDetail = false
    },
    clickSubNav(i) {
      this.lsHoverIdx = i
      this.dataList.page = 1
      this.itemKey = 0
      this.dataList.list = []
      this.loadData()
    },
    clickItem(item) {
      this.isDetail = true
      this.$router.push(
        `/newsCenter?type=${this.lsHoverIdx}&isDetail=1&id=${item.key}`
      )
      console.log('itemkey', item)
      this.itemKey = item.key
    },
    loadData() {
      this.isLoading = true
      this.$axios({
        url: '/haojiang-portal/frontNewsCenter/getNewsPage',
        method: 'post',
        data: {
          param: {
            status: 1,
            type: this.lsHoverIdx + 1,
          },
          pageSize: this.dataList.pageSize,
          page: this.dataList.page,
          orders: {},
          // 	排序列表（key:需要排序的字段名 value:[asc(升序),desc(倒序)]）
        },
      }).then((r) => {
        this.isLoading = false
        if (r.data.page > 1) {
          this.dataList.list = [...this.dataList.list, ...r.data.list]
          // this.dataList.list.concat(r.data.list)
        } else {
          this.dataList = r.data
        }

        if (r.data.totalPage !== 0 && !(this.itemKey <= this.dataList.list.length - 1)) {
          this.dataList.page++
          this.loadData()
        }
      })
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    swiper,
    swiperSlide,
    ColumnNewLayout,
    ToTopperComponent,
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  created() {
    this.$route.query.isDetail != 1 && (nextPage = '')
    console.log(nextPage)
    this.isDetail = this.$route.query.isDetail === '1'
    this.lsHoverIdx = +this.$route.query.type || 0
    this.itemKey = +this.$route.query.id || 0
    this.$axios('/haojiang-portal/frontNewsCenter/banner').then(
      (r) => (this.bannerUrl = r.data[0].url || '')
    )
    this.loadData()
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from.name)
    const specialRoutes = ['homepage']
    if (specialRoutes.includes(from.name)) {
      nextPage = from && from.name
    } else {
      nextPage = ''
    }
    next()
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './news-center.less';
</style>
