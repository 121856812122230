/*
 * @Descripttion:
 * @Author: zcy
 * @Date: 2021-10-27 09:41:35
 * @LastEditors: zcy
 * @LastEditTime: 2022-09-06 11:29:43
 */
/**
 * @Author: zcy
 * @Date: 2021-10-27 09:41:51
 * @Descripttion:
 * @params {*}
 */
import axios from '@/axios'

/**
 * @Author: zcy
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取入驻企业信息
 * @params {*}
 * @param {*} type
 */
export function ZhanyuQiye(type){
  return  axios({
    url:`/haojiang-portal/frontRoaming/queryCompanyList`,
    methods:'get'
  })
}

/**
 * @Author: zcy
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取产业园空间布局介绍
 * @params {*}
 * @param {*} type
 */
 export function ZhanyuCyy(type){
  return  axios({
    url:`/haojiang-portal/frontRoaming/getParkLayout/${type}`,
    methods:'get'
  })
}

/**
 * @Author: zcy
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取产业园空间布局介绍
 * @params {*}
 * @param {*} type
 */
 export function ZhanyuCyyBanner(type){
  return  axios({
    url:`/haojiang-portal/frontRoaming/getBanner`,
    methods:'get'
  })
}
